<template>
  <div class="form-wrap-comment">
        <form class="comment-container">
          <div class="inputs">
            <div class="input">
              <label for="comment"><h2></h2></label>
              <span>
                <textarea id="comment"  class="comment-text" v-model="comment" name="comment" placeholder="Drop a Comment..."/>
              </span>
            </div>
          </div>
          <button @click.prevent="postComment">Submit</button>
        </form>
      </div>
</template>

<script>
import db from "../firebase/firebaseInit";
export default {
  data() {
    return {
      comment: "",
    };  
  },
  methods: {
    async postComment() {
      const timestamp = await Date.now();
      const dataBase = await db.collection("comments").doc();
      const blogid = this.$route.params.blogid;
      await dataBase.set({
        blogID: blogid,
        userID: this.$store.state.profileUsername,
        time: timestamp,
        comment: this.comment,
        firstName: this.$store.state.profileFirstName,
        lastName: this.$store.state.profileLastName,
      });
      this.comment = "";
      window.location.reload();
    },
  },
}
</script>

<style lang="scss">

.form-wrap-comment {
  padding: 40px 40px;
  height: 40vh;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  @media (min-width: 900px) {
    width: auto;
  }

  .comment-container {

    h2 {
      text-align: center;
      font-size: 32px;
      color: #303030;
      margin-bottom: 10px;

      @media(min-width: 900px) {
        font-size: 24px;
      }
    }
    ::placeholder {
          color: rgba(45, 45, 45, .5);
    }
    .comment-text input:focus + ::placeholder {
      color: #fff;
    }
    span {
        background-color: rgba(191, 172, 5, .95);
      }
    .comment-text {
      resize: none;
      flex: 1;
      border: 1px solid #CCCCCC;
      border-radius: 5px 5px 5px 5px;
      font-family: Verdana,Arial,Helvetica,sans-serif;
      font-size: 18px;
      margin: 0;
      overflow: hidden;
      padding: 5px;
      width: 700px;

      @media (max-width: 900px) {
        width: 500px;
      }

      @media (max-width: 700px) {
        width: 300px;
      }

      @media (max-width: 400px) {
        width: 100%;
      }

      float: left;
      height: 150px;
      transition: .25s ease all;
      &:focus {
        border: 1px outset #303030;
        outline: none;
      }
      &:focus::after {
          background-color: rgba(191, 172, 5, .95);
        }
      
    }
  button {
    margin-top: 10px;
    float: right;
  }
  }
}
</style>