<template>
  <div>
    <div class="comment-card">
      <div class="info">
        <h4>{{comment.firstName}} {{comment.lastName}}</h4>
        <h6>Posted on: {{new Date(comment.time).toLocaleString('en-us', {dateStyle: 'medium', timeStyle: 'short'})}}</h6>
        <p>{{comment.comment}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commentCard",
  props: ['comment'],
  components: {
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.comment-card {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  background-color: rgba(48,48,48, 0.05);
  min-height: 50px;
  transition: 0.5s ease all;

  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .icons {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.5s ease all;

      &:hover {
        background-color: #303030;

        .edit,
        .delete {
          path {
            fill: #fff;
          }
        }
      }

      &:nth-child(1) {
        margin-right: 8px;
      }

      .edit {
        pointer-events: none;
        height: 15px;
        width: auto;
      }
    }
  }

  img {
    display: block;
    border-radius: 8px 8px 0 0;
    z-index: 1;
    width: 100%;
    min-height: 200px;
    object-fit: cover;
  }

  .info {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    padding: 32px 16px;
    color: #000;

    h4 {
      padding-bottom: 8px;
      font-size: 20px;
      font-weight: 300;
    }

    h6 {
      font-weight: 400;
      font-size: 12px;
      padding-bottom: 16px;
    }

    .link {
      display: inline-flex;
      align-items: center;
      margin-top: auto;
      font-weight: 500;
      padding-top: 20px;
      font-size: 12px;
      padding-bottom: 4px;
      transition: 0.5s ease-in all;

      &:hover {
        color: rgba(48, 48, 48, 0.8);
      }

      .arrow {
        width: 10px;
      }
    }
  }
}
</style>