<template>
  <div class="post-view" v-if="currentBlog">
    <div class="container quillWrapper">
      <h2>{{ this.currentBlog[0].blogTitle }}</h2>
      <h4>Posted on: {{ new Date(this.currentBlog[0].blogDate).toLocaleString("en-us", { dateStyle: "long" }) }}</h4>
      <img :src="this.currentBlog[0].blogCoverPhoto" alt="" />
      <div class="post-content ql-editor" v-html="this.currentBlog[0].blogHTML"></div>
      <div class="comments-container" v-show="commentsExist">
        <h2>Comments</h2>
        <CommentCard :comment="comment" v-for="(comment, index) in comments" :key="index"/>
      </div>
      <PostComment v-if="user"/>
      <div class="container-btn" v-if="!user">
        <router-link class="router-button" :to="{name: 'Register'}">
          Leave a Comment <Arrow class="arrow arrow-light"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '../assets/Icons/arrow-right-light.svg'
import PostComment from "../components/PostComment.vue";
import CommentCard from "../components/CommentCard.vue";
export default {
    name: "ViewBlog",
    data() {
        return {
            currentBlog: null,
            comment: "",
        };
    },
    async mounted() {
        this.currentBlog = await this.$store.state.blogPosts.filter((post) => {
            return post.blogID === this.$route.params.blogid;
        });
        await this.$store.commit("setCurrentBlogID", this.$route.params.blogid);
        await this.$store.dispatch("loadComments");
        console.log(this.$store.state.currentBlogID);
    },
    components: { 
      Arrow,
      PostComment,
      CommentCard,  
    },
    computed: {
      comments() {
        return this.$store.state.comments;
      },
        user() {
        return this.$store.state.user;
      },
        commentsExist() {
          return this.$store.state.comments.length !== 0;
      },
    }
};
</script>

<style lang="scss">
.post-view {
  h4 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
  }
}

.container-btn {
    padding: 100px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: 800px) {
      padding: 125px 25px;
      flex-direction: row;
    }

    .router-button {
      display: flex;
      font-size: 14px;
      text-decoration: none;
      @media(min-width: 800px) {
        margin-left: auto;
      }
    }

    h2 {
      font-weight: 300;
      font-size: 32px;
      max-width: 425px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      @media(min-width: 800px) {
        text-align: initial;
        font-size: 40px;
      }
    }
  }

.comments-container {
  h2 {
      text-align: center;
      font-size: 32px;
      color: #303030;
      margin-bottom: 40px;
      margin-top: 80px;
      padding: 30px 0 8px;
      position: relative;
      @media(min-width: 900px) {
        font-size: 40px;
      }
    }

  h2::before {
    content: "";
    position: absolute;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 72px;
    bottom: 16px;
    width: 200px;
    height: 1.25px;
    transform: skew(-12deg) translateX(-50%);
    background: rgba(48, 48, 48, 0.5);
    z-index: -1;
  }
}


</style>