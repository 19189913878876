<template>
  <div>
    <div class="reset-password">
      <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal" />
      <Loading v-if="loading" />
      <div class="form-wrap">
        <form class="reset">
          <p class="login-register">
            Back to
            <router-link class="router-link" :to="{name: 'Login'}">Login</router-link>
          </p>
          <h2>Reset Password</h2>
          <p>Forgot your password? Enter your email to reset it</p>
          <div class="inputs">
            <div class="input">
              <input type="email" required placeholder="Email" v-model="email" />
              <email class="icon" />
            </div>
            <div v-show="error" class="error">{{this.errorMsg}}</div>
          </div>
          <button @click.prevent="resetPassword">Reset</button>
          <div class="angle"></div>
        </form>
        <div class="background"></div>
      </div>
    </div>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import Modal from "../components/Modal.vue";
import Loading from "../components/Loading.vue";
import firebase from "firebase/app";
import "firebase/auth";
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      modalActive: false,
      modalMessage: "",
      loading: null,
      error: null,
      errorMsg: "",
    }
  },
  components: {
    email,
    Modal,
    Loading,
},
methods: {
  validateEmail(email) {
  const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const test = res.test(String(email).toLowerCase());
  if (!test) this.errorMsg = 'Please enter a valid email';
  else this.errorMsg = '';
  return test;
},
  resetPassword() {
    this.error = !this.validateEmail(this.email);
    console.log(this.error);
    if (!this.error) {
      this.loading = true;
      firebase.auth().sendPasswordResetEmail(this.email).then(() => {
        this.modalMessage = "If your account exists, you will recieve a password reset email";
        this.loading = false;
        this.modalActive = true;
      }).catch(() => {
        this.modalMessage = "If your account exists, you will recieve a password reset email";
        this.loading = false;
        this.modalActive = true;
      });
    }
  },
  closeModal() {
    this.modalActive = !this.modalActive;
    this.email = ""; 
  },
}
}
</script>

<style lang="scss" scoped>
.reset-password {
  position: relative;
  .form-wrap {
    .reset {
      h2 {
        margin-bottom: 8px;
      }
      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }
  }
}
</style>