import firebase from "firebase/app";
import "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDESExtqW5oP8iYIdjBPVNVUosBPSM2JUY",
  authDomain: "isaacwrites-3d2c9.firebaseapp.com",
  projectId: "isaacwrites-3d2c9",
  storageBucket: "isaacwrites-3d2c9.appspot.com",
  messagingSenderId: "70927529495",
  appId: "1:70927529495:web:c5ce7bcca6a1a4282a0e52"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {timestamp};
export default firebaseApp.firestore();
