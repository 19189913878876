<template>
  <div>
    <div class="form-wrap">
    <form class="login">
      <p class="login-register">
        Already have an account?
        <router-link class="router-link" :to="{name: 'Login'}">Login</router-link>
      </p>
      <h2>Create your Account</h2>
      <div class="inputs">
        <div class="input">
          <input type="text" placeholder="First Name" v-model="firstName">
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="Last Name" v-model="lastName">
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="Username" v-model="username">
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="Email" v-model="email">
          <email class="icon"/>
        </div>
        <div class="input">
          <input type="password" placeholder="Password" v-model="password">
          <password class="icon"/>
        </div>
        <div class="input">
          <input type="password" placeholder="Confirm Password" v-model="secondaryPass">
          <password class="icon"/>
        </div>
        <div class="input">
          <div class="subscribe">
            <input type="checkbox" :checked="subscribed" v-model="subscribed">
            <div class="info">
              <h4>Receive email updates when new blogs are posted</h4> 
            </div>
          </div>
        </div>
        <div v-show="error" class="error">{{this.errorMsg}}</div>
      </div>
      <button @click.prevent="register">Sign up</button>
      <div class="angle"></div>
    </form>
    <div class="background"></div>
  </div>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import password from "../assets/Icons/lock-alt-solid.svg";
import user from "../assets/Icons/user-alt-light.svg";
import firebase from "firebase/app";
import "firebase/auth";
import db from "../firebase/firebaseInit";
export default {
  name: "Register",
  components: {
    email,
    password,
    user,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      secondaryPass: "",
      error: null,
      errorMsg: "",
      subscribed: true,
    };
  },
  methods: {
    async register() {
      if (
        this.email !== "" &&
        this.password !== "" &&
        this.secondaryPass !== "" &&
        this.firstName !== "" &&
        this.lastName !== "" &&
        this.username !== ""
      ) {
        if (this.password == this.secondaryPass) {
          this.error = false;
          this.errorMsg = "";
          const firebaseAuth = await firebase.auth();
          const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password).then().catch(err => {
            this.error = true;
            this.errorMsg = err.message;
          });
          const result = await createUser;
          const dataBase = db.collection("users").doc(result.user.uid);
          await dataBase.set({
              firstName: this.firstName,
              lastName: this.lastName,
              username: this.username,
              email: this.email,
              subscribed: this.subscribed,
          });
          this.$router.push({name: 'Home'});
        } else 
        {
          this.error = true;
          this.errorMsg = "Passwords do not match!";
        }
      } else
      {
        this.error = true;
        this.errorMsg = "Please fill out all the fields!";
      }
      
    },
  }
}
</script>

<style lang="scss" scoped>
.register {
  h2 {
    max-width: 350px;
  }
}
.subscribe {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  margin-left: -155px;
  
  .info {
    margin-left: -60px;
  }

  input {
    transform: scale(.4);
  }
}
</style>