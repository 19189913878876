<template>
  <div class="profile">
    <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal" />
    <div class="container">
      <h2>Account Settings</h2>
      <div class="profile-info">
        <div v-if="admin" class="admin-badge">
          <adminIcon class="icon" />
          <span>admin</span>
        </div>
        <div class="input">
          <label for="firstName">First Name:</label>
          <input type="text" id="firstName" v-model="firstName" />
        </div>
        <div class="input">
          <label for="lastName">Last Name:</label>
          <input type="text" id="lastName" v-model="lastName" />
        </div>
        <div class="input">
          <label for="username">Username:</label>
          <input type="text" id="username" v-model="username" />
        </div>
        <div class="input">
          <label for="email">Email:</label>
          <input disabled type="text" id="email" v-model="email" />
        </div>
        <div id="inputPreview">
          <input name="subCheckbox" id="sub-check_id" type="checkbox" class="sub-check" :checked="subscribed" v-model="subscribed">
          <label for="sub-check_id">Subscribed</label>
        </div>
        <button @click="updateProfile">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '../components/Modal.vue';
import adminIcon from '../assets/Icons/user-crown-light.svg';

export default {
  name: "Profile",
  components: { 
    Modal,
    adminIcon, 
  },
  data() {
    return { 
      modalMessage: "Changes were saved!",
      modalActive: null,
    }
  },
  methods: {
    closeModal() {
      this.modalActive = !this.modalActive;
    },
    updateProfile() {
      this.$store.dispatch("updateUserSettings");
      this.modalActive = !this.modalActive;
    },
  },
  computed: { 
    firstName: {
      get() {
        return this.$store.state.profileFirstName;
      },
      set(payload) {
        this.$store.commit("changeFirstName", payload);
      },
      admin() {
        return this.$store.state.profileAdmin;
      },
    },
    lastName: {
      get() {
        return this.$store.state.profileLastName;
      },
      set(payload) {
        this.$store.commit("changeLastName", payload);
      }
    },
    username: {
      get() {
        return this.$store.state.profileUsername;
      },
      set(payload) {
        this.$store.commit("changeFirstName", payload);
      }
    },
    subscribed: {
      get() {
        return this.$store.state.subscribed;
      },
      set(payload) {
        this.$store.commit("changeSubscription", payload);
      }
    },
    email() {
        return this.$store.state.profileEmail;
    }
  }
}
</script>

<style lang="scss" scoped>
 .profile {
  .container {
    max-width: 1000px;
    padding: 60px 25px;

    h2 {
      text-align: center;
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 32px;
    }

    .profile-info {
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 32px;
      background-color: #ddc3a2;
      display: flex;
      flex-direction: column;
      max-width: 600px;
      margin: 32px auto;

      .admin-badge {
        display: flex;
        align-self: center;
        color: #000;
        font-size: 14px;
        padding: 8px 24px;
        border-radius: 8px;
        background-color: #1eb8b8;
        margin: 16px 0;
        text-align: center;
        text-transform: capitalize;

        .icon {
          width: 14px;
          height: auto;
          margin-right: 8px;
        }
      }

      .input {
        margin: 16px 0;

        label {
          font-size: 14px;
          display: block;
          padding-bottom: 6px;
        }
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 8px;
          height: 50px;
          @media (min-width: 900px) {

          }

          &:focus {
            outline: none;
          }
        }
      }

      button {
        align-self: center;
        transition: 500ms ease all;
        color: #fff;
        border-radius: 20px;
        border: none;
        text-transform: uppercase;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: rgba(78, 78, 78, 0.7);
        }
      }

      .sub-check { 
        position: absolute; 
        overflow: hidden; 
        clip: rect(0 0 0 0); 
        height: 1px; 
        width: 1px; 
        margin: -1px;
        padding: 0; 
        border: 0; 
      }
      
      #inputPreview {
        display: flex; 
        gap: 20px; 
        justify-content: center;	
      }
        
      .sub-check + label {
        position: relative; 
        font-size: 17px; 
        cursor: pointer; 
        display: inline-flex; 
        align-items: center; 
        height: 27px; 
        color: rgb(0, 0, 0); 
      }
        
      .sub-check + label::before {
        content: " "; 
        display: inline-block; 
        vertical-align: middle; 
        margin-right: 3px; 
        width: 23px; 
        height: 23px; 
        background-color: #f2f7f6; 
        border-width: 2px; 
        border-style: solid; 
        border-color: rgb(204, 204, 204); 
        border-radius: 5px; 
        box-shadow: none; 
      }
        
      .sub-check:checked + label::after {
        content: " "; 
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIiIGQ9Ik0xNzMuODk4IDQzOS40MDRsLTE2Ni40LTE2Ni40Yy05Ljk5Ny05Ljk5Ny05Ljk5Ny0yNi4yMDYgMC0zNi4yMDRsMzYuMjAzLTM2LjIwNGM5Ljk5Ny05Ljk5OCAyNi4yMDctOS45OTggMzYuMjA0IDBMMTkyIDMxMi42OSA0MzIuMDk1IDcyLjU5NmM5Ljk5Ny05Ljk5NyAyNi4yMDctOS45OTcgMzYuMjA0IDBsMzYuMjAzIDM2LjIwNGM5Ljk5NyA5Ljk5NyA5Ljk5NyAyNi4yMDYgMCAzNi4yMDRsLTI5NC40IDI5NC40MDFjLTkuOTk4IDkuOTk3LTI2LjIwNyA5Ljk5Ny0zNi4yMDQtLjAwMXoiLz48L3N2Zz4="); 
        background-repeat: no-repeat; 
        background-size: 14px 14px; 
        background-position: center center; 
        position: absolute; 
        display: flex; 
        justify-content: center; 
        align-items: center; 
        margin-left: 0px; 
        left: 0px; 
        top: 0px; 
        text-align: center; 
        background-color: transparent; 
        font-size: 10px; 
        height: 27px; 
        width: 27px; 
      }
    }
  }
 }
</style>